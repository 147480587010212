
$(document).ready(function(){

    var HeaderTop = $('#header').offset().top;

    $(window).scroll(function(){
        if( $(window).scrollTop() > 56 ) {
            $('#header').addClass('fixed');
        } else {
            $('#header').removeClass('fixed');
        }
    });




    //Sidebar Links Open/Close
    var is_sm;
    $(function(){
        if ( $(window).width() < 544 ) {
            is_sm = true;
        }   else {
            is_sm = false;
        }
    });



    $(window).resize(function () {
        if ( $(window).width() < 544 ) {
            is_sm = true;
            $('.side-nav__links').slideUp(0);
            $('.side-nav__cap--btn').removeClass('clicked');
        }   else {
            is_sm = false;
            $('.side-nav__links').slideDown(0);
        }

    });
    $('.side-nav__cap--btn').on('click', function (e) {
        var clicked = this;
        if ( is_sm ){
            e.preventDefault();
            $('.side-nav__cap--btn').not(clicked).removeClass('clicked');
            $('.side-nav__links').not($(clicked).siblings('.side-nav__links')).slideUp();
            $(clicked).toggleClass('clicked').siblings('.side-nav__links').slideToggle();
        }
    });

    //Menu open
    var m_open = $('.btn--burger');
    var m_close = $('.btn--close');
    var bar_bg = $('.sidebar-bg');
    var body = $('.doc-body');
    var bar = $('.side-nav');
    var nav_link = $('.side-nav__link');

    $(m_open).on('click', function () {
        $(m_open).fadeToggle(10);
        $(body).addClass('side-toggled');
        $(bar_bg).fadeIn();
        $(bar).fadeIn(0).addClass('nav-toggled').css("display", "flex");
    });

    //Menu close
    $(m_close).on('click', function () {
        $(bar).removeClass('nav-toggled').delay(300).fadeOut(0);
        $(bar_bg).fadeOut();
        $(body).removeClass('side-toggled');
        $(m_open).fadeToggle();
    });

    $(bar_bg).on('click', function (e) {
        if (e.target == this) {
            $(bar).removeClass('nav-toggled').delay(300).fadeOut(0);
            $(bar_bg).fadeOut();
            (body).removeClass('side-toggled');
            $(m_open).fadeToggle();
        }
    });

    //Anchoring
    $(nav_link).on("click", function (event) {
        var link = $(this);
        var id = $(link).attr('href').split('#')[1];
        var dest_page = $(link).attr('href').split('#')[0].split('/')[1];
        var curr_page = window.location.pathname.split('/')[1];

        if(dest_page === curr_page){
            event.preventDefault();
            var top = $('#' + id).offset().top;
            $('body,html').animate({scrollTop: top}, 800);
            $(bar).removeClass('nav-toggled').delay(300).fadeOut(0);
            $(bar_bg).fadeOut();
            (body).removeClass('side-toggled');
            $(m_open).fadeToggle();
        }else {
            $(bar).removeClass('nav-toggled').delay(300).fadeOut(0);
            $(bar_bg).fadeOut();
            (body).removeClass('side-toggled');
            $(m_open).fadeToggle();
        }
    });

    $('.side-nav__cap--btn').on('click', function (event) {
        var link = $(this);
        var dest_page = $(link).attr('href').split('/')[1];
        var curr_page = window.location.pathname.split('/')[1];

        if((dest_page === curr_page) && (!is_sm)){
            event.preventDefault();
            $('body,html').animate({scrollTop: 0}, 800);
            $(bar).removeClass('nav-toggled').delay(300).fadeOut(0);
            $(bar_bg).fadeOut();
            (body).removeClass('side-toggled');
            $(m_open).fadeToggle();
        }
    });

    $(document).on("click", ".btn--to-top", function (event) {
        $('body,html').animate({scrollTop: 0}, 800);
    });

    wow = new WOW(
        {
            boxClass:     'wow',
            animateClass: 'animated',
            offset:       0,
            mobile:       true,
            live:         true,
        }
    );
    wow.init();


    $('.multi-wrapper').slick({
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        speed: 1000,
        adaptiveHeight: true,
    });



    $("#scroll-h").mCustomScrollbar({
        theme:"rounded",
        axis: "x",
        advanced:{autoExpandHorizontalScroll:true},
        mouseWheel: "disabled",
    });

    $("#f-contacts").validate({
        rules:{
            accept: "required",
            c_fname: {
                required: true,
                minlength: 3
            },
            c_lname: {
                required: true,
                minlength: 3
            },
            c_org: {
                required: true,
                minlength: 3
            },
        },
        errorPlacement: function(error, element) {},
        highlight: function(element) {
            $(element).parent().addClass("field-error");
            $(element).addClass("error");
        },
        unhighlight: function(element) {
            $(element).parent().removeClass("field-error");
            $(element).removeClass("error");
        }
    });


    var i = 0.1;
    $('.customer__img').each(function () {
        var elem = this;
        $(elem).attr('data-wow-delay', i + 's');
        i = i + 0.1;
    });
    i = 0.1;
    $('.partner__img').each(function () {
        var elem = this;
        $(elem).attr('data-wow-delay', i + 's');
        i = i + 0.1;
    });

    $(function() {
        $('.illustration__img, .c-grid__img, .service-tape__img, .post-pic').lazy();
    });


});